import { makeAutoObservable, runInAction } from 'mobx';
import request from '@/tools/httpClient';
import { common } from '@/api';
import type { MenuDataItem } from '@/const/route.config';

import type { StateProps } from '@/types/common/store';
import { PLATFORM_TYPES } from '@/const/csc';
import { message } from 'antd';

class CommonStore {
  [key: string]: any;

  singleHref = '';
  currentMenu: MenuDataItem | null = null; // 当前菜单
  csInfo: {
    csRight?: boolean;
    isAdmin?: boolean;
    hasSeat?: boolean;
    unreadMessages?: number;
    seatStatus?: number;
  } = {};

  constructor() {
    makeAutoObservable(this);
  }

  // 获取消息列表
  remindGetListByPage = (params: any) => {
    return new Promise((resolve, reject) => {
      try {
        request.post(common.remindGetListByPage, params).then(({ data, success }) => {
          if (success) {
            resolve(data);
          }
          reject();
        });
      } catch (ex) {
        reject();
      }
    });
  };

  // 统计消息数量
  remindCountNum = (params: any) => {
    return new Promise((resolve, reject) => {
      try {
        request.post(common.remindCountNum, params).then(({ data, success }) => {
          if (success) {
            resolve(data);
          }
          reject();
        });
      } catch (ex) {
        reject();
      }
    });
  };

  // 消息设为已读
  remindRead = (params: any) => {
    return new Promise((resolve, reject) => {
      try {
        request.post(common.remindRead, params).then(({ data, success }) => {
          if (success) {
            resolve(true);
          }
          reject();
        });
      } catch (ex) {
        reject();
      }
    });
  };

  // 获取验证码（通用）
  getSmsCode = (params: any) => {
    return new Promise((resolve, reject) => {
      try {
        request.post(common.smsCode, params).then(({ data, success }) => {
          if (success) {
            resolve(true);
          }
          reject();
        });
      } catch (ex) {
        reject();
      }
    });
  };

  // 获取角标
  tabStatistical = (params: any, systemId: any) => {
    return new Promise((resolve, reject) => {
      try {
        request
          .post(common.tabStatistical, params, {
            headers: {
              system_id: systemId,
            },
          })
          .then(({ data, success }) => {
            if (success) {
              resolve(data);
            }
            reject();
          });
      } catch (ex) {
        reject();
      }
    });
  };

  // 获取行业列表
  getCompanyIndustryList = async (): Promise<any> => {
    return new Promise((resolve, reject) => {
      try {
        request
          .get(common.industryList)
          .then(({ data, success }) => {
            if (success && data) {
              resolve(data);
            }
            reject();
          })
          .catch((err) => {
            reject(err);
          });
      } catch (ex) {
        reject();
      }
    });
  };

  // 获取猜你喜欢
  getGuessList = async (params: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      try {
        request
          .get(common.getGuessList, { params })
          .then(({ data, success }) => {
            if (success && data) {
              resolve(data);
            }
            reject();
          })
          .catch((err) => {
            reject(err);
          });
      } catch (ex) {
        reject();
      }
    });
  };

  // 获取客服权限信息
  getCsPermissionInfo = async (): Promise<any> => {
    request
      .post(common.csPermission, {}, { headers: { platform_type: PLATFORM_TYPES.smartercarp_cs } })
      .then(({ data, success }) => {
        if (success && data) {
          this.updateState({
            csInfo: data || {},
          });
        } else {
          this.updateState({ csInfo: {} });
        }
      })
      .catch(() => {
        this.updateState({ csInfo: {} });
      });
  };
  // 看板url
  completedUrl = async (params: {
    panelNo: string;
    employeeIdList?: string[];
  }): Promise<{
    panelUrl: string;
    expireTime: number;
  }> => {
    return new Promise((resolve, reject) => {
      try {
        request.post(common.completedUrl, params).then(({ data, success }) => {
          if (success) {
            resolve(data);
          }
          reject();
        });
      } catch (err: any) {
        message.error(err.message);
        reject();
      }
    });
  };
  // 查看门户信息
  queryInfo = async (): Promise<any> => {
    return new Promise((resolve, reject) => {
      try {
        request.post(common.queryInfo).then(({ data, success }) => {
          if (success) {
            resolve(data);
          }
          console.log(data);
          reject();
        });
      } catch (err: any) {
        message.error(err.message);
        reject();
      }
    });
  };
  updateState = (params: StateProps) => {
    for (const [key, value] of Object.entries(params)) {
      runInAction(() => {
        this[key] = value;
      });
    }
  };
}

export default new CommonStore();
