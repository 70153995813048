import React, { useState, useEffect } from 'react';
import WujieReact from 'wujie-react';
import { observer } from 'mobx-react';
import Cookies from 'js-cookie';
import { useLocation, useOutletContext } from 'react-router-dom';

import { Loading, ErrorPage } from '@/components';
import { PageCacheStore, AccountStore } from '@/stores';
import { ApplicationInfo } from '@/const/application';
import { ApplicationUrlMap } from '@/const/serviceEnv';
import { useTabNavigate } from '@/hooks/useTabNavigate';
import UploadQueue from '@/tools/upload';

import type { UserInfo } from '@/stores/account';
import type { MenuDataItem } from '@/const/route.config';

import './index.less';

const { systemId } = ApplicationInfo;

const eventHandlers = new WeakMap();

export const pptxUploadQueue = new UploadQueue(5);

interface WujieReactItem {
  path: string;
  singleTab?: boolean;
  pathname: string;
  WujieReactDom: React.ReactElement;
}

interface OutletProps {
  userInfo: UserInfo;
  currentMenu: MenuDataItem;
  flatMenus: Record<string, MenuDataItem> | null;
}

const wujiePlugins = [
  {
    jsBeforeLoaders: [
      {
        content: `
        window.axios = window.parent.axios;
        window.React = window.parent.React;
        window.ReactDOM = window.parent.ReactDOM;
        window.classnames = window.parent.classnames;
        window.moment = window.parent.moment;
        window.Selection = window.parent.Selection;
        window.global = global || globalThis || window;
      `,
      },
    ],
    cssBeforeLoaders: [
      {
        content: `
        body{background:transparent !important;overflow:auto;}
        #root{display:flex;flex-direction:column;}
        .fx-router-loading{min-height:calc(100vh - 92px) !important;}
      `,
      },
    ],
    windowAddEventListenerHook(
      iframeWindow: typeof Window,
      type: string,
      handler: Function,
      options: Object,
    ) {
      if (['mousedown'].includes(type)) {
        const _handler = function (e: Event) {
          const [target] = e.composedPath() as EventTarget[];
          Object.defineProperty(e, 'target', {
            value: target,
            writable: true,
            enumerable: true,
            configurable: true,
          });
        };
        eventHandlers.set(handler, _handler);
        window.addEventListener(type, _handler, options);
      }
    },
    windowRemoveEventListenerHook(
      iframeWindow: typeof Window,
      type: string,
      handler: Function,
      options: Object,
    ) {
      if (['mousedown'].includes(type)) {
        const _handler = eventHandlers.get(handler);
        eventHandlers.delete(handler);
        window.removeEventListener(type, _handler, options);
      }
    },
  },
];

const MicroApp: React.FC = () => {
  const [uninitialized, setUninitialized] = useState(true);
  const navigate = useTabNavigate();
  const location = useLocation();
  const { currentMenu, flatMenus, userInfo } = useOutletContext<OutletProps>();

  const [errors, setErrors] = useState<{ [key: string]: boolean }>({});
  const [wujieReactList, setWujieReactList] = useState<WujieReactItem[]>([]);

  // 透传给子应用的props
  const wjProps = {
    systemId,
    systemIds: [systemId],
    contentOffsetTop: 92,
    getUserInfo() {
      return userInfo;
    },
    ApplicationUrlMap,
    // 跳转页面
    navigate: (
      urlOrDelta: string | number,
      options?: { target?: string; replace?: string; state?: { [key: string]: any } },
    ) => {
      if (typeof urlOrDelta === 'string') {
        if (options?.target === '_blank') {
          window.open(urlOrDelta, options.target);
          return;
        }

        navigate(urlOrDelta, { state: options?.state });
        return;
      }

      if (urlOrDelta === 0) {
        PageCacheStore.refreshPage(`${location.pathname}${location.search}`).then((path) => {
          navigate('/redirectPage', { state: { path, ...(options?.state || {}) }, replace: true });
        });
        return;
      }

      navigate(urlOrDelta);
    },
    // 关闭当前页面
    closeSinglePage: (path: string) => PageCacheStore.closePageTab(path),
    // 关闭所有页面
    closeAllPage: () => {
      PageCacheStore.closeAllTabs();
      navigate(PageCacheStore.homePage.path);
    },
    // 重定向到某个页面并刷新（不传即为当前页）
    refreshPage: (path?: string) => {
      PageCacheStore.refreshPage(path || `${location.pathname}${location.search}`).then((path) => {
        navigate('/redirectPage', { state: { path }, replace: true });
      });
    },
    // 退出登录
    logoutAndGoToLogin: (url?: string) => {
      AccountStore.logout().then(async () => {
        await Cookies.remove('token');
        pptxUploadQueue.cleanAuth();
        const redirectUrl = `?redirectUrl=${encodeURIComponent(window.location.href)}`;
        const loginUrl = `/account/login${redirectUrl}`;
        const CookiesLink = Cookies.get('loginLink')?.replace(/^https?:\/\//i, '');
        const CookiesLoginLink = CookiesLink ? `https://${CookiesLink}${redirectUrl}` : loginUrl;
        const loginLink = AccountStore.userInfo?.loginLink
          ? `https://${AccountStore.userInfo?.loginLink}${redirectUrl}`
          : CookiesLoginLink;

        window.location.replace(url || loginLink);
      });
    },
    // ppt 方案上传
    pptxUploadQueue,
  };

  useEffect(() => {
    if (currentMenu) {
      setWujieReactList((wujieReactList) => {
        const pathname = window.location.href.replace(window.location.origin, '');
        const url = pathname.replace(/^\/module/, '');

        const singleTab = JSON.parse(decodeURIComponent(currentMenu?.props || '{}'))?.singleTab;
        const currentPath = `${location.pathname}${singleTab ? '' : location.search}`;

        const onScrollEnd = (e: Event) => {
          const target = e.target as HTMLBodyElement;
          target.setAttribute('data-scrollTop', target.scrollTop.toString());
        };

        const WujieReactDom = (
          <WujieReact
            fiber
            alive
            name={currentPath}
            width="100%"
            height="100%"
            props={wjProps}
            url={url}
            key={url}
            plugins={wujiePlugins}
            loading={null}
            afterMount={(window: Window) => {
              window.document.body.addEventListener('scrollend', onScrollEnd);
            }}
            beforeUnmount={(window: Window) => {
              window.document.body.removeEventListener('scrollend', onScrollEnd);
            }}
            activated={(window: Window) => {
              WujieReact.bus.$emit('page-chnage');
              const scrollTop = +(window.document.body.getAttribute('data-scrollTop') || '0');
              window.document.body.scrollTo(0, scrollTop);
            }}
            loadError={() => setErrors((errors) => ({ ...errors, [currentMenu?.path]: true }))}
            replace={(codeText) => codeText.replace(/const global = globalThis;\s*/g, '')}
          />
        );
        const newWujieReactList: WujieReactItem[] = [...wujieReactList];
        const foundindex = newWujieReactList.findIndex((item) => {
          return singleTab ? item.pathname === location.pathname : item.path === currentPath;
        });

        const newWujieReact = {
          singleTab,
          WujieReactDom,
          path: currentPath,
          pathname: location.pathname,
        };

        if (singleTab) {
          if (foundindex > -1) {
            newWujieReactList.splice(foundindex, 1, newWujieReact);
          } else {
            newWujieReactList.push(newWujieReact);
          }
        } else if (foundindex === -1) {
          newWujieReactList.push(newWujieReact);
        }

        return newWujieReactList;
      });
    }
  }, [currentMenu]);

  useEffect(() => {
    flatMenus && setUninitialized(false);
  }, [flatMenus]);

  // 未初始化时
  if (uninitialized) return <Loading spinning />;

  if (!currentMenu) return <ErrorPage status="noAuth" />;

  const { singleTab } = JSON.parse(decodeURIComponent(currentMenu?.props || '{}'));
  const currentPath = `${location.pathname}${singleTab ? '' : location.search}`;

  const currentWujieReact = wujieReactList.find(({ path, singleTab, pathname }) => {
    return singleTab
      ? location.pathname === pathname
      : `${location.pathname}${location.search}` === path;
  })?.WujieReactDom;

  return (
    <div className="wujie-container">
      {errors[currentPath] ? <ErrorPage status="error" /> : currentWujieReact}
    </div>
  );
};
export default observer(MicroApp);
