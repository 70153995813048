import React from 'react';
import { Navigate } from 'react-router-dom';
import type { RoutesType } from '@fle-ui/plus-router';
import { getApplicationUrl } from '@/const/serviceEnv';

import { ErrorPage } from '@/components';
import Layout from '@/layout';
import MicroApp from '@/pages/microApp';

const LiguanjiaMemberUrl = getApplicationUrl('liguanjia');

export interface match<Params extends { [K in keyof Params]?: string } = Record<string, any>> {
  params: Params;
  isExact: boolean;
  path: string;
  url: string;
}
export interface MenuDataItem {
  /** @name 子菜单 */
  children?: MenuDataItem[] | null;
  /** @name 是否显示面包屑 */
  showBreadcrumb?: boolean | null;
  /** @name 在菜单中隐藏子节点 */
  hideChildrenInMenu?: boolean | null;
  /** @name 在菜单中隐藏自己和子节点 */
  hideInMenu?: boolean | null;
  /** @name 菜单的icon */
  icon?: string;
  /** @name 自定义菜单的国际化 key */
  locale?: string | false;
  /** @name 菜单的名字 */
  name: string;
  /** @name 菜单描述 */
  description?: string | null;
  /** @name 用于标定选中的值，默认是 path */
  key?: string;
  /** @name disable 菜单选项 */
  disabled?: boolean;
  /** @name 路径,可以设定为网页链接 */
  path: string;
  /** @name 隐藏自己，并且将子节点提升到与自己平级 */
  flatMenu?: boolean;
  /** @name 指定外链打开形式，同a标签 */
  target?: string | null;
  /** @name permissionKey 菜单权限标识 */
  permissionKey?: string;
  /** @name permission 是否始终有权限 */
  permission?: boolean | null;
  /** @name redirect 路由重定向 */
  redirect?: string | null;
  /** @name pureMenu 纯净模式，不展示左侧菜单 */
  pureMenu?: boolean | null;
  /** @name 路由类型：主应用、微应用、iframe */
  routerType?: 'main' | 'micro' | 'iframe'; // 默认主应用
  /** @name props 额外参数, 需要用decodeURIComponent解码 */
  props?: string | null;

  component?: React.ComponentType<any>;

  layout_keys?: string[];
  layout_names?: string;

  [key: string]: any;
}

export type Route = MenuDataItem;

const Routes: RoutesType = [
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '',
        element: <Navigate to="/module/workbench/dashboard" replace />,
      },
      {
        path: '/404',
        element: <ErrorPage status="404" />,
      },
      {
        path: 'noAuth',
        element: <ErrorPage status="noAuth" />,
      },
      {
        path: '/redirectPage',
        component: () => import(/* webpackChunkName: "redirectPage" */ '@/pages/redirectPage'),
      },
      {
        path: '/webview/lgj/mall/setting/order/daily/report',
        meta: {
          title: '订单每日简报',
          url: `${LiguanjiaMemberUrl}/team_message/sms_notification_setting`,
        },
        component: () => import('@/pages/webview'),
      },
      {
        path: '/dataDesktop/supplier/board',
        meta: {
          title: '供应商数据看板',
        },
        component: () => import('@/pages/dataBord'),
      },
      {
        path: '/dataDesktop/customer/board',
        meta: {
          title: '客户数据看板',
        },
        component: () => import('@/pages/customerBoard'),
      },
      {
        path: '*',
        element: <MicroApp />,
      },
      {
        path: '/module/showroom/outMallPage',
        meta: {
          title: '中转页面',
        },
        component: () => import('@/pages/outMallPage'),
      },
    ],
  },
];

export default Routes;
