import React, { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import useMeasure from 'react-use-measure';
import { CaretRightOutlined } from '@ant-design/icons';

import './index.less';

interface CollapsablePanelProps {
  label: string;
  children: React.ReactNode;
}

const CollapsablePanel: React.FC<CollapsablePanelProps> = ({ label, children }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [ref, bounds] = useMeasure();

  const togglePanel = () => {
    setIsCollapsed((prevState) => !prevState);
  };

  const toggleWrapperAnimatedStyle = useSpring({
    transform: isCollapsed ? 'rotate(0deg)' : 'rotate(90deg)',
    config: { duration: 150 },
  });
  const panelContentAnimatedStyle = useSpring({
    height: isCollapsed ? 0 : bounds.height,
    config: { duration: 150 },
  });
  return (
    <div className="pannel">
      <div className="heading" onClick={togglePanel}>
        <animated.div style={toggleWrapperAnimatedStyle}>
          <CaretRightOutlined />
        </animated.div>
        <span className="heading-title">{label}</span>
      </div>
      <animated.div style={panelContentAnimatedStyle} className="content">
        <div ref={ref} className="contentInner">
          {children}
        </div>
      </animated.div>
    </div>
  );
};

export default CollapsablePanel;
