import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';
import NP from 'number-precision';
import Cookies from 'js-cookie';
import { Tooltip, Badge, Modal } from 'antd';
import WujieReact from 'wujie-react';
import { CustomerServiceOutlined, DownOutlined } from '@ant-design/icons';
import { Icon, FloatingButton } from '@/components';
import { ProLayout } from '@ant-design/pro-components';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';
import defaultSettings from '@/const/defaultSettings';
import { AccountStore, CommonStore, PageCacheStore } from '@/stores';
import Message from '@/components/Message';
import HeaderMenu from './HeaderMenu';
import SiderMenu from './SiderMenu';
import TabMenu from './TabMenu';
import HeaderLeftContent from './HeaderLeftContent';
import AvatarPopover from './AvatarPopover';
import getFlatMenus from '@/tools/getFlatMenus';
import { observer } from 'mobx-react';
import { ApplicationInfo } from '@/const/application';
import NoticeDrawer from './NoticeDrawer';
import getMatchMenu from '@/tools/getMatchMenu';
import { BaseURL, TokenKey, getApplicationUrl } from '@/const/serviceEnv';
import TabLink from '@/components/TabLink';
import { useFavicon, useTitle } from 'ahooks';
import account from '@/stores/account';
import type { UserInfo } from '@/stores/account';
import type { MenuDataItem } from '@/const/route.config';
import ProProvider from '@fle-ui/plus-context';

import { PLATFORM_TYPES } from '@/const/csc';

import './index.less';

type BandgeFunType = 'approve' | 'message';

const { systemId, actionsIcon, title } = ApplicationInfo;

// 格式化菜单，为每个菜单添加 layout_parentKeys
const formartMenu = (
  menuData: MenuDataItem[],
  parentNames: string[] = [],
  parentKeys: string[] = [],
): MenuDataItem[] => {
  return menuData.map((item) => {
    const __item = {
      ...item,
      layout_keys: [...parentKeys, item.path || ''],
      layout_names: `${parentNames.join('-')}-${item.name || ''}`,
    };
    if (__item.children?.length) {
      __item.children = formartMenu(
        __item.children,
        [...parentNames, __item.name || ''],
        [...parentKeys, __item.path || ''],
      );
    }

    return __item;
  });
};

const { bus } = WujieReact;

const BasicLayout: React.FC = () => {
  const location = useLocation();
  const navigator = useNavigate();
  const [faviconUrl, setFaviconUrl] = useState<string>('');
  const [browserTitle, setBrowserTitle] = useState<string>('');

  const { systemIds = [] } = useContext(ProProvider);

  useFavicon(faviconUrl);
  useTitle(browserTitle);

  const { csInfo } = CommonStore;
  const [messageOpen, setMessageOpen] = useState<boolean>(false);
  const [messageNum, setMessageNum] = useState<number | null>(null);
  const [menu, setMenu] = useState<MenuDataItem[]>([]);
  const [userInfo, setUserInfo] = useState<UserInfo>();

  // const csTimer = useRef<any>();
  // 扁平化菜单，用于搜索，避免频繁计算
  const [flatMenus, setFlatMenus] = useState<Record<string, MenuDataItem> | null>(null);
  useLayoutEffect(() => {
    AccountStore.getMenuPermission({
      systemId,
      resourceTypeList: [1, 2],
    }).then(async (res) => {
      const formartPermissionMenu = formartMenu(res, [title]);
      setMenu(formartPermissionMenu);

      const __flatMenus = formartPermissionMenu.length ? getFlatMenus(formartPermissionMenu) : null;
      setFlatMenus(__flatMenus);
    });
    AccountStore.getUserInfo().then((data) => {
      setUserInfo(data);
      window.localStorage.setItem('__C_U_ID__', data.id);
      setFaviconUrl(data.logoImgUrl || ApplicationInfo.logo);
      setBrowserTitle(data.portalName || ApplicationInfo.title);
    });
    // 获取站点id
    AccountStore.getSiteId();
  }, []);

  const [matchMenu, setMatchMenu] = useState<MenuDataItem[]>([]);
  const [currentMenu, setCurrentMenu] = useState<MenuDataItem>();
  useEffect(() => {
    const filterPath = ['/noAuth', '/redirectPage'];
    if (flatMenus && !filterPath.includes(location.pathname)) {
      const pathname =
        location.pathname === '/redirectPage' ? location.state?.path : location.pathname;
      const _matchMenu = getMatchMenu(pathname, flatMenus, false, true);

      const checkMenu = _matchMenu?.[_matchMenu.length - 1];

      if (checkMenu) {
        if (checkMenu.redirect) {
          navigator(checkMenu.redirect, { replace: true });
          return;
        }

        CommonStore.updateState({
          currentMenu: checkMenu,
        });
        setMatchMenu(_matchMenu);
        // 解构以解决 search 参数更新页面不刷新的问题
        setCurrentMenu({ ...checkMenu });

        const { singleTab } = JSON.parse(decodeURIComponent(checkMenu.props || '{}'));

        const path = `${location.pathname}${singleTab ? '' : location.search}`;
        const key = `${location.pathname}${singleTab ? '' : location.search}`;
        const name = checkMenu.name || '标签页';

        PageCacheStore.updatePageTab({
          key,
          path,
          name,
          singleTab,
        });
      } else {
        navigator('/noAuth', { replace: true });
      }
    }
  }, [flatMenus, location]);

  const [awaitApproveCount, setAwaitApproveCount] = useState<number>(0);
  const getApproveCountNum = async () => {
    const data: any = await CommonStore.tabStatistical({}, systemIds[0]);
    setAwaitApproveCount(data.awaitApproveCount);
  };
  useEffect(() => {
    getApproveCountNum();

    getCsPermissionInfo();
  }, []);

  // useEffect(() => {
  //   if (csInfo.csRight && ((csInfo.hasSeat && csInfo.seatStatus === 1) || csInfo.isAdmin)) {
  //     csTimer.current = setInterval(getCsPermissionInfo, 20000);
  //   } else {
  //     csTimer.current && clearInterval(csTimer.current);
  //   }

  //   return () => {
  //     csTimer.current && clearInterval(csTimer.current);
  //   };
  // }, [csInfo]);

  // 判断用户是否改变
  useEffect(() => {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        AccountStore.checkUserId().then((data: any) => {
          if (!data) {
            window.location.replace('/module/workbench/dashboard');
          }
        });
      } else {
        // 当前标签页变为不可见状态
        // 执行相应的操作
      }
    });
  }, []);

  function getCsPermissionInfo() {
    try {
      CommonStore.getCsPermissionInfo();
      // eslint-disable-next-line no-empty
    } catch {}
  }

  useEffect(() => {
    getMessageCountNum();
  }, []);
  // 判断用户是否改变
  useEffect(() => {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        AccountStore.checkUserId().then((data) => {
          if (!data) {
            window.location.replace('/module/workbench/dashboard');
          }
        });
      } else {
        // 当前标签页变为不可见状态
        // 执行相应的操作
      }
    });
  }, []);

  // 获取消息数量
  const getMessageCountNum = async () => {
    const param = {
      channelType: 10,
      isRead: -10, // 未读数量
    };
    const data: any = await CommonStore.remindCountNum(param);
    setMessageNum(NP.plus(data?.systemNum, data?.businessNum));
  };

  useEffect(() => {
    bus.$on('updateBandgeNum', (types: BandgeFunType[]) => {
      // 重新获取消息数
      const BangdeFunMap: { [key in BandgeFunType]: Function } = {
        approve: getApproveCountNum,
        message: getMessageCountNum,
      };

      types.forEach((type) => BangdeFunMap[type] && BangdeFunMap[type]());
    });
  }, []);

  return (
    <>
      <ProLayout
        {...defaultSettings}
        location={location}
        className="peliten__layout"
        logo={ApplicationInfo.textLogo}
        title={ApplicationInfo.title}
        siderWidth={180}
        style={{ flexDirection: 'row', height: '100vh' }}
        headerRender={(_, defaultDom) => <div id="peliten__layout__header">{defaultDom}</div>}
        headerTitleRender={() => <HeaderLeftContent userInfo={userInfo} />}
        avatarProps={{
          src: '//oss.elebuys.com/tmpdir/202308171743390002128752.png',
          title: userInfo?.name,
          style: { background: '#bbcfff' },
          render: (_, dom) => (
            <AvatarPopover userInfo={userInfo}>
              <div style={{ whiteSpace: 'nowrap' }}>
                {dom}
                <DownOutlined style={{ fontSize: 12, marginLeft: 10 }} />
              </div>
            </AvatarPopover>
          ),
        }}
        headerContentRender={() => (
          <HeaderMenu
            menuData={menu}
            flatMenus={flatMenus}
            matchMenuKeys={currentMenu?.layout_keys}
          />
        )}
        actionsRender={() => {
          const actionsMap = new Map([
            [
              'csc',
              <Tooltip title="小鲤客服">
                <a
                  className="fle-header-action-item"
                  onClick={() => {
                    const cscUrl = getApplicationUrl('csc');

                    const params: any = {
                      siteId: Cookies.get('siteId') || '',
                      platform_type: PLATFORM_TYPES.smartercarp_cs,
                      token: Cookies.get(TokenKey) || '',
                      redirectUrl: encodeURIComponent(window.location.origin),
                    };

                    const paramsStr = Object.keys(params)
                      .map((key) => `${key}=${params[key]}`)
                      .join('&');

                    window.open(`${cscUrl}/?${paramsStr}`, 'CscWindow');
                  }}
                >
                  <Badge size="small" count={csInfo.unreadMessages}>
                    <CustomerServiceOutlined size={16} />
                  </Badge>
                </a>
              </Tooltip>,
            ],
            [
              'importAndExport',
              <Tooltip title="导入导出">
                <TabLink to="/module/common/importAndExport" className="fle-header-action-item">
                  <Icon type="icon-icon_20pt_xiazaizhongxin_weixuanzhong" />
                </TabLink>
              </Tooltip>,
            ],
            [
              'vetting',
              <Tooltip title="审批中心">
                <TabLink to="/module/common/vetting" className="fle-header-action-item">
                  <Badge
                    size="small"
                    offset={[8, -5]}
                    count={awaitApproveCount}
                    style={{ top: 5, right: 0 }}
                  >
                    <Icon type="icon-icon_16pt_shenpizhongxin_weixuanzhong" />
                  </Badge>
                </TabLink>
              </Tooltip>,
            ],
            [
              'message',
              <Tooltip title="消息中心">
                <div
                  className="fle-header-action-item fle-header-action-item-div"
                  onClick={() => {
                    setMessageOpen(true);
                  }}
                >
                  <Badge size="small" count={messageNum}>
                    <Icon
                      type="icon-icon_20pt_tongzhi_weixuanzhong"
                      className="fle-header-action-item-icon"
                    />
                  </Badge>
                </div>
              </Tooltip>,
            ],
            [
              'carpdance',
              <Tooltip title="妙鲤">
                <div
                  style={{ padding: '0 6px' }}
                  onClick={async () => {
                    let token = '';
                    try {
                      const res = await axios.post(
                        `/elebuys/user/login-free/login?__date=${Date.now()}`,
                        '100005',
                        {
                          baseURL: BaseURL,
                          timeout: 30000,
                          headers: {
                            system_id: '100005',
                            authority_token: Cookies.get(TokenKey),
                            'Content-Type': 'text/plain',
                          },
                        },
                      );
                      token = res.data.data?.token;
                    } catch (error) {
                      //
                    }

                    if (!token) {
                      Modal.warning({
                        title: '提示',
                        content: '您的妙鲤平台会员权益已到期，请联系您的客户经理进行增购',
                      });
                      return;
                    }

                    const url = getApplicationUrl('wowcarp', `?token=${token}`);
                    window.open(url);
                  }}
                >
                  <img
                    style={{
                      width: 20,
                      height: 20,
                      objectFit: 'contain',
                      transform: 'translateY(-1px)',
                    }}
                    src="https://oss.elebuys.com/logo/wowcarp/wowcarp-logo.png"
                    alt=""
                  />
                </div>
              </Tooltip>,
            ],
          ]);
          return actionsIcon
            .filter((icon) => {
              switch (icon) {
                case 'csc':
                  return (
                    csInfo.csRight &&
                    ((csInfo.hasSeat && csInfo.seatStatus === 1) || csInfo.isAdmin)
                  );
                case 'carpdance':
                  return userInfo?.plusFlag === 24;
                default:
                  return true;
              }
            })
            .map((type) => actionsMap.get(type));
        }}
        menuRender={() => (
          <SiderMenu
            menuData={menu}
            flatMenus={flatMenus}
            matchMenu={matchMenu}
            currentMenu={currentMenu}
          />
        )}
        footerRender={false}
      >
        <div className="fx__layout-content">
          <TabMenu matchMenu={matchMenu} currentMenu={currentMenu} />
          <Outlet
            context={{
              userInfo,
              currentMenu,
              matchMenu,
              flatMenus,
            }}
          />
        </div>
      </ProLayout>

      <Message />
      <FloatingButton />
      <NoticeDrawer
        open={messageOpen}
        setMessageOpen={setMessageOpen}
        setMessageNum={setMessageNum}
      />
    </>
  );
};

export default observer(BasicLayout);
