import React from 'react';
import { Skeleton } from 'antd';
import type { UserInfo } from '@/stores/account';
import { ApplicationInfo } from '@/const/application';
import './index.less';

interface HeaderLeftContentProps {
  userInfo?: UserInfo;
}
const HeaderLeftContent: React.FC<HeaderLeftContentProps> = ({ userInfo }) => {
  return (
    <>
      <div className="fle-layout-header-left-content">
        <div className="fle-layout-header-logo">
          {userInfo ? (
            <>
              <img src={userInfo?.portalLogo || ApplicationInfo.textLogo} alt="logo" />
            </>
          ) : (
            <>
              <Skeleton.Avatar active size={28} shape="square" />
              <Skeleton.Button active size="default" />
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default React.memo(HeaderLeftContent);
