import React from 'react';
import CollapsablePanel from './CollapsablePanel';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { useTabNavigate } from '@/hooks/useTabNavigate';
import type { MenuDataItem } from '@/const/route.config';
import './index.less';

export interface CollapsableMenuProps {
  items: MenuDataItem[];
  activeKey?: string[];
  matchMenuKeys?: string[];
}

const CollapsableMenu: React.FC<CollapsableMenuProps> = ({
  items,
  activeKey = [],
  matchMenuKeys = [],
}) => {
  const location = useLocation();
  const navigator = useTabNavigate();

  if (items?.length === 0) return null;

  return (
    <div className="collapsable-wrap">
      {items.map((item) => {
        const actived = activeKey?.includes(item.path || '');

        if (item.children?.length) {
          return (
            <CollapsablePanel key={item.path} label={item.name}>
              <div className="collapsable-panel-wrap">
                {item.children?.map((child: MenuDataItem) => {
                  const { path, redirect, name } = child;
                  return (
                    <div
                      key={path}
                      className={classNames('collapsable-panel-item', {
                        active: matchMenuKeys.includes(path),
                      })}
                      onClick={() => navigator(redirect || path)}
                    >
                      {name}
                    </div>
                  );
                })}
              </div>
            </CollapsablePanel>
          );
        }

        return (
          <span
            key={item.path}
            className={classNames('heading heading-single-title', {
              active: actived || item.path === location.pathname,
            })}
            onClick={() => navigator(item.redirect || item.path)}
          >
            {item.name}
          </span>
        );
      })}
    </div>
  );
};

export default React.memo(CollapsableMenu);
