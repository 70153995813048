import {
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import {
  ModalForm,
  ProFormCheckbox,
  ProFormInstance,
  ProFormSelect,
  ProFormCascader,
} from '@ant-design/pro-components';
import { Button, Form, ConfigProvider, Input } from 'antd';
import AddressSelection from '@fle-ui/plus-address-selection';
import { AccountStore, CommonStore } from '@/stores';
import Cookies from 'js-cookie';

import type { Timeout } from 'ahooks/lib/useRequest/src/types';
import type { DefaultOptionType } from 'antd/es/cascader';
import './index.less';

const scaleObj = {
  1: '10人以下',
  2: '11-30人',
  3: '31-60人',
  4: '61-100人',
  5: '101-200人',
  6: '201-500人',
  7: '501-1000人',
  8: '1000人以上',
};

export interface CreateTeamRefType {
  setModalVisible: Dispatch<SetStateAction<boolean>>;
}

const CreateTeam = forwardRef<CreateTeamRefType, {}>((_, cRef) => {
  const formRef = useRef<ProFormInstance>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [finishMadalVisible, setFinishMadalVisible] = useState<boolean>(false);
  const [waitTime, setWaitTime] = useState<number>(5);
  const [id, setId] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  useImperativeHandle(cRef, () => ({
    setModalVisible,
  }));

  const timerId = useRef<Timeout>(); // 创建一个可以在组件的整个生命周期中保持不变的引用

  useEffect(() => {
    // 清除定时器的函数会在组件卸载时被调用
    return () => {
      if (timerId.current) {
        clearInterval(timerId.current);
      }
    };
  }, []);

  const timeStart = () => {
    timerId.current = setInterval(() => {
      setWaitTime((prevTime) => prevTime - 1);
    }, 1000);
  };

  // 确认创建团队
  const confirmEvent = () => {
    formRef?.current
      ?.validateFields()
      .then(async (values) => {
        setLoading(true);
        const params = {
          ...values,
          industry: values?.industry?.value,
          provinceCode: values?.address?.[0],
          cityCode: values?.address?.[1],
          countyCode: values?.address?.[2],
          townCode: values?.address?.[3],
        };
        const data = await AccountStore.companyCreate(params);
        if (data) {
          formRef.current?.resetFields();
          setId(data as string);
          setModalVisible(false);
          setFinishMadalVisible(true);
          timeStart();
        }
      })
      .finally(() => setLoading(false));
  };

  const filter = (inputValue: string, path: DefaultOptionType[]) => {
    return path.some(
      (option) => (option.name as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1,
    );
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          borderRadius: 8,
        },
        components: {
          Input: {
            paddingBlockLG: 12,
          },
          Select: {
            singleItemHeightLG: 54,
          },
        },
      }}
    >
      <ModalForm
        title="创建新团队"
        open={modalVisible}
        formRef={formRef}
        modalProps={{
          onCancel: () => {
            setModalVisible(false);
            formRef.current?.resetFields();
          },
          styles: {
            body: {
              marginTop: 20,
            },
          },
          maskClosable: false,
        }}
        width={520}
        className="create-company-modal"
        submitter={{
          render: () => {
            return (
              <div style={{ flexDirection: 'column', width: '100%' }}>
                <Button
                  block
                  key="ok"
                  type="primary"
                  size="large"
                  onClick={confirmEvent}
                  className="fx__btn"
                  loading={loading}
                >
                  确认创建
                </Button>
              </div>
            );
          },
        }}
      >
        <Form.Item name='name' rules={[
          {
            required: true,
            message: '请输入企业/个体团队名称',
          },
        ]}>
        <Input
        type="text"
        maxLength={50}
        placeholder='请输入企业/个体团队名称(必填)'
        style={{
          height: 54,
          borderRadius: 10,
          fontSize: 16
        }}
        />
      </Form.Item>
      
      <Form.Item name='position' rules={[
          {
            required: false,
            message: '请输入您在该团队的职务名称',
          }
        ]}>
        <Input
        type="text"
        maxLength={6}
        placeholder="请输入您在该团队的职务名称"
        style={{
          height: 54,
          borderRadius: 10,
          fontSize: 16
        }}/>
      </Form.Item>
        <ProFormCascader
          allowClear
          secondary
          name="industry"
          placeholder="请选择行业"
          request={async () => (await CommonStore.getCompanyIndustryList()) || []}
          rules={[
            {
              required: false,
              message: '请选择行业',
            },
          ]}
          fieldProps={{
            size: 'large',
            className: 'fx__select',
            showSearch: { filter },
            fieldNames: {
              label: 'name',
              value: 'id',
            },
          }}
        />
        <ProFormSelect
          name="scale"
          fieldProps={{
            size: 'large',
            className: 'fx__select',
          }}
          valueEnum={scaleObj}
          placeholder="请选择人员规模"
          rules={[{ required: false, message: '请选择人员规模' }]}
        />
        <Form.Item name="address" rules={[{ required: false, message: '地址不能为空' }]}>
          <AddressSelection placeholder="请选择地址" size="large" className="fx__select" />
        </Form.Item>
        <div style={{ position: 'relative' }}>
          <ProFormCheckbox
            name="protocols"
            rules={[
              () => ({
                validator(_, value) {
                  if (value) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(new Error('请勾选同意协议'));
                  }
                },
              }),
            ]}
          />
          <div className="protocols-text">
            创建团队的同时，我同意遵守
            <span
              className="text-primary-color pointer"
              onClick={(event) => event.stopPropagation()}
            >
              <a href="https://store.fxqifu.com/front-end/#/article?store_article_id=618" target="_blank" rel="noreferrer">《百鲤跃川软件服务协议》</a>
            </span>
            及
            <span
              className="text-primary-color pointer"
              onClick={(event) => event.stopPropagation()}
            >
               <a href="https://store.fxqifu.com/front-end/#/article?store_article_id=617" target="_blank" rel="noreferrer">《隐私声明》</a>
            </span>
          </div>
        </div>
      </ModalForm>
      <ModalForm
        className="finish-modal"
        open={finishMadalVisible}
        modalProps={{
          maskClosable: false,
          onCancel: () => {
            setFinishMadalVisible(false);
          },
        }}
        width={480}
        submitter={{
          render: () => {
            return (
              <div className="flex flex-center" style={{ width: '100%' }}>
                <Button
                  block
                  className="fx__btn"
                  type="primary"
                  disabled={waitTime > 0}
                  onClick={async () => {
                    setFinishMadalVisible(false);
                    const token: string = await AccountStore.changeCompany({ id });
                    if (token) {
                      Cookies.set('token', token);
                      window.location.reload();
                    }
                  }}
                >
                  {waitTime > 0 ? `进入新团队(${waitTime}s)` : '进入新团队'}
                </Button>
              </div>
            );
          },
        }}
      >
        <img src="https://oss.elebuys.com/tmpdir/202310181444320003728632.png" alt="" />
        <p>新团队创建成功！</p>
      </ModalForm>
    </ConfigProvider>
  );
});

export default CreateTeam;
