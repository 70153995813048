import type { MenuDataItem } from '@/const/route.config';
import {
  stripQueryStringAndHashFromPath
} from './transformRoute';

/**
 * 获取打平的 menuData
 * 以 path 为 key
 * @param menuData
 */
export const getFlatMenus = (
  menuData: MenuDataItem[] = [],
): Record<string, MenuDataItem> => {
  let menus: Record<string, MenuDataItem> = {};
  menuData.forEach((mapItem) => {
    const item = { ...mapItem };
    if (!item || !item.path) {
      return;
    }
    const routerChildren = item.children || [];
    menus[stripQueryStringAndHashFromPath(item.path || '/')] = {
      ...item,
    };
    menus[item.path || '/'] = { ...item };

    if (routerChildren) {
      menus = { ...menus, ...getFlatMenus(routerChildren) };
    }
  });
  return menus;
};

export default getFlatMenus;