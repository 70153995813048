import React, { useState, useEffect } from 'react';
import { Tabs } from 'antd';
import { useTabNavigate } from '@/hooks/useTabNavigate';
import { PageCacheStore } from '@/stores';
import type { MenuDataItem } from '@/const/route.config';

import './index.less';

interface TabMenuProps {
  currentMenu?: MenuDataItem | null;
  matchMenu: MenuDataItem[];
}
const TabMenu: React.FC<TabMenuProps> = ({ currentMenu, matchMenu }) => {
  const navigate = useTabNavigate();
  const [activeKey, setActiveKey] = useState<string>();

  useEffect(() => {
    setActiveKey(currentMenu?.redirect || currentMenu?.path);
  }, [currentMenu]);

  const thridMenuItem = matchMenu[2]; // TODO：目前只会对三级菜单进行判断，后续有其他需求再调整
  const { showTabMenu } = JSON.parse(decodeURIComponent(thridMenuItem?.props || '{}'));

  const menuTabItems = thridMenuItem?.children
    ?.filter((item) => !item.hideInMenu)
    ?.map((item) => {
      return {
        key: item.redirect || item.path,
        label: item.name,
      };
    });

  if (menuTabItems?.length === 0 || !showTabMenu) return null;

  const handleChange = (key: string) => {
    setActiveKey(key);
    PageCacheStore.refreshPageByKey(thridMenuItem.path).then(() => {
      navigate('/redirectPage', {
        state: { path: key },
        replace: true,
      });
    });
    navigate(key);
  };

  return (
    <div className="fle-layout-tab-menu">
      <Tabs activeKey={activeKey} items={menuTabItems} onChange={handleChange} />
    </div>
  );
};
export default React.memo(TabMenu);
