import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import PlusConfigProvider from '@fle-ui/plus-config-provider';
import Router from '@/router';
import theme from '@fle-ui/plus-theme';
import { fleAppEnv } from './const/serviceEnv';


import '@/theme/reset.less';
import '@/style/index.less';

const { defaultTheme,  } = theme;

const container = document.getElementById('root');

const root = ReactDOM.createRoot(container!);
window.localStorage.setItem('env', fleAppEnv || '');

root.render(
  <PlusConfigProvider theme={defaultTheme}>
    <Router />
  </PlusConfigProvider>,
);
