import Cookies from 'js-cookie';
type actionType = 'csc' | 'message' | 'importAndExport' | 'vetting' | 'carpdance';
export interface ApplicationItemType {
  systemId: string;
  logo: string;
  textLogo: string;
  title: string;
  description?: string;
  dashboard: string;
  actionsIcon: actionType[];
}
const loginType = Cookies.get('loginType') || 90;
const pageInfoLogintype: any = {
  90: {
    systemId: '100001',
    title: '智鲤',
    logo: 'https://oss.elebuys.com/resource/goods/materials/202404091541470001243490.png',
    textLogo: 'https://oss.elebuys.com/resource/goods/materials/202404091541190001243488.png',
    description: '企业数智化解决方案',
    dashboard: '/module/workbench/dashboard', // 首页
    actionsIcon: ['csc', 'importAndExport', 'vetting', 'message', 'carpdance'],
  },
  180: {
    systemId: '100001',
  },
  190: {
    systemId: '100008',
  },
  200: {
    systemId: '100009',
    title: '智鲤',
    logo: 'https://oss.elebuys.com/resource/goods/materials/202404091541470001243490.png',
    textLogo: 'https://oss.elebuys.com/resource/goods/materials/202404091541190001243488.png',
    description: '企业数智化解决方案',
    dashboard: '/module/workbench/dashboard', // 首页
    actionsIcon: ['csc', 'importAndExport', 'vetting', 'message', 'carpdance'],
  },
};

export const ApplicationInfo: ApplicationItemType = pageInfoLogintype[loginType];
