import React, { useMemo, useState, useEffect } from 'react';
import { Menu, Typography } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import PageTabs from '@/layout/PageTabs';
import { Icon, CollapsableMenu } from '@/components';
import { useTabNavigate } from '@/hooks/useTabNavigate';

import type { MenuDataItem } from '@/const/route.config';
import type { MenuProps } from 'antd';

import './index.less';

const { Paragraph } = Typography;

type MenuItem = Required<MenuProps>['items'][number];
const getItem = (options: {
  label: React.ReactNode;
  key: string;
  icon?: string;
  children?: MenuItem[];
  type?: 'group' | 'divider';
}): MenuItem => {
  const { label, key, icon, children, type } = options;
  return {
    key,
    children,
    label,
    type,
    icon: icon ? <Icon type={icon} /> : undefined,
  };
};

interface SiderMenuProps {
  menuData: MenuDataItem[];
  flatMenus: Record<string, MenuDataItem> | null;
  currentMenu?: MenuDataItem;
  matchMenu: MenuDataItem[];
}

const SiderMenu: React.FC<SiderMenuProps> = ({ menuData, flatMenus, currentMenu, matchMenu }) => {
  const navigator = useTabNavigate();

  const [collapsed, setCollapsed] = useState<boolean>(false);

  const { layout_keys: matchMenuKeys = [], pureMenu = true } = currentMenu || {};

  const [key] = matchMenuKeys;

  let siderMenuData: MenuDataItem[] = [];

  let firstMenuItem: MenuDataItem | undefined;

  if (key) {
    firstMenuItem = menuData?.find((item) => item.path === key);
    siderMenuData = firstMenuItem?.children?.filter((item) => !item.hideInMenu) || [];
  }

  const looperMenu = (menuData?: MenuDataItem[] | null) => {
    const allSiderKeys: string[] = [];

    const formatMenu = menuData
      ?.filter((item) => !item.hideInMenu)
      .reduce<MenuItem[]>((res, item) => {
        const { path = '', icon, name, type, children, hideChildrenInMenu } = item;
        const { showTabMenu, showExtendMenu } = JSON.parse(decodeURIComponent(item?.props || '{}'));

        if (children?.length) {
          allSiderKeys.push(path);
        }

        res.push(
          getItem({
            label: name,
            key: path,
            icon,
            children:
              showTabMenu || showExtendMenu || hideChildrenInMenu
                ? undefined
                : looperMenu(children).siderMenuItems,
            type,
          }),
        );

        return res;
      }, []);

    return {
      allSiderKeys,
      siderMenuItems: formatMenu?.length ? formatMenu : undefined,
    };
  };

  const { siderMenuItems } = useMemo(() => looperMenu(siderMenuData), [firstMenuItem?.path]);

  const thirdMatchMenuItem = matchMenu?.[2];
  const { showExtendMenu } = JSON.parse(decodeURIComponent(thirdMatchMenuItem?.props || '{}'));

  const subMenuData =
    showExtendMenu && thirdMatchMenuItem.children?.length ? thirdMatchMenuItem.children : [];

  const looperSubMenu = (menuData?: MenuDataItem[] | null) => {
    const formatMenu = menuData
      ?.filter((item) => !item.hideInMenu)
      .reduce<MenuDataItem[]>((res, item) => {
        const { children, hideChildrenInMenu } = item;
        const { showTabMenu, showExtendMenu } = JSON.parse(decodeURIComponent(item?.props || '{}'));

        res.push({
          ...item,
          children:
            showTabMenu || showExtendMenu || hideChildrenInMenu
              ? undefined
              : looperSubMenu(children),
        });

        return res;
      }, []);

    return formatMenu?.length ? formatMenu : undefined;
  };

  const subMenuItems = looperSubMenu(subMenuData);

  const menuWidth = useMemo(() => {
    const siderMenuWidth = collapsed ? 58 : 180;
    const subMenuWidth = subMenuItems?.length ? 170 : 0;
    return {
      siderMenuWidth,
      width: siderMenuWidth + subMenuWidth,
    };
  }, [collapsed, subMenuItems]);

  const [openKeys, setOpenKeys] = useState<string[]>([]);

  useEffect(() => {
    if (matchMenuKeys.length) {
      setOpenKeys(Array.from(new Set([...openKeys, ...matchMenuKeys])));
    }
  }, [matchMenuKeys]);

  return (
    <>
      <div
        className="fle-layout-sider"
        style={{ minWidth: currentMenu && !pureMenu ? menuWidth.width : 0 }}
      >
        {currentMenu && !pureMenu && (
          <div className="fle-menu">
            <div className="fle-menu-space" style={{ width: menuWidth.siderMenuWidth }} />
            <div className="fle-menu-wrap">
              <div
                className="menu-action-wrap"
                onClick={() => setCollapsed(!collapsed)}
                style={{ left: collapsed ? 45 : 168 }}
              >
                {collapsed ? (
                  <RightOutlined className="menu-action-close-icon" />
                ) : (
                  <LeftOutlined className="menu-action-open-icon" />
                )}
              </div>

              <div
                className="fle-menu-sider fle-scroll"
                style={{ width: menuWidth.siderMenuWidth }}
              >
                <Menu
                  mode="inline"
                  theme="light"
                  inlineIndent={20}
                  items={siderMenuItems}
                  inlineCollapsed={collapsed}
                  selectedKeys={matchMenuKeys}
                  openKeys={collapsed ? undefined : openKeys}
                  onOpenChange={(keys) => setOpenKeys(keys)}
                  onClick={({ key }) => {
                    const currentMenu = flatMenus?.[key];
                    const url = currentMenu?.redirect || currentMenu?.path || key;
                    if (currentMenu?.target === '_blank') {
                      window.open(url);
                    } else {
                      navigator(url);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        )}

        {subMenuData.length > 0 && (
          <div className="fle-submenu-wrap fle-scroll" style={{ left: menuWidth.siderMenuWidth }}>
            <Paragraph
              ellipsis={{ rows: 1, tooltip: currentMenu?.name || false }}
              className="fle-submenu-current"
            >
              当前选项：{currentMenu?.name}
            </Paragraph>
            <CollapsableMenu items={subMenuItems || []} matchMenuKeys={matchMenuKeys} />
          </div>
        )}
      </div>

      <PageTabs collapsed={collapsed} currentMenu={currentMenu} />
    </>
  );
};
export default React.memo(SiderMenu);
