export default {
  memberInfo: '/elebuys/member/employee/member-info', // 获取账号信息
  logout: '/elebuys/right/login/logout', // 退出登录
  logoutOther: '/welcome/logout', // 3.0退出登录
  fetchLiguanjiaToken: '/welcome/loginByToken', // 获取3.0token
  getApplictionPermission: '/elebuys/jack/right/userSystemPermission', // 获取应用权限
  getMenuPermission: '/elebuys/right/v2/userRole/userSystemPermissionTree', // 获取菜单权限
  getUserPermission: '/elebuys/right/v2/userRole/getUserPermissionByPermissionKey', // 根据PermissionKey获取用户权限
  getSiteId: 'fleshopy/merchant/site/siteDecoration/getUsableInfo', // 获取站点id
  getCompanyList: '/elebuys/user/login/v2/employee-info', // 获取该用户下所有公司
  changeCompany: '/elebuys/user/login/v2/enter-company', // 切换公司
  createCompany: '/elebuys/member/company/create', // 创建企业
};
