export const PLATFORM_TYPES = {
  pointsShop: '201001', // 万鲤商联-积分商城-用户
  cashShop: '201002', // 万鲤商联-现金商城-用户
  stageShop: '201003', // 万鲤商联-分期商城-用户
  card: '201004', // 万鲤商联-礼包卡券-用户
  carpunion_cs: '201000', // 万鲤商联-会员平台-客服

  marketCloudShop: '101001', // 智鲤-营销云-用户
  smartercarp_cs: '101000', // 智鲤-会员平台-客服

  carpdance_cs: '301000', // 百鲤跃川-平台客服
  carpdance: '302000', // 百鲤跃川-官网
  carpdance_user: '302001', // 百鲤跃川-咨询-用户
};
