import { getMainDomain } from '@/tools/common';
import type { FleAppEnv, EnvURLMap } from '@/types/const/serviceEnv';

export const fleAppEnv: FleAppEnv = fleProcess.fleEnv;
type ServerType = 'java';
type BaseURLMap = {
  [K in ServerType]: EnvURLMap;
};
export const baseURLMap: BaseURLMap = {
  java: {
    dev: 'https://dev-gateway.fxqifu.net/pangu',
    qa: 'https://qa-gateway.fxqifu.net/pangu',
    prod: 'https://gateway.fxqifu.net/pangu',
  },
};

type codeType = number;
export const DataCode: Map<string, codeType> = new Map([
  ['SUCCESS', 2000],
  ['LOGIN_ERROR', 100003],
  ['INVALID_LOGIN', 60000013],
]);

export const BaseServiceType = 'java';
export const TokenKey = 'token';
export const BaseURL: string = baseURLMap[BaseServiceType][fleAppEnv] || '';

export type ApplicationType =
  | 'login'
  | 'dist'
  | 'help'
  | 'cardStore'
  | 'liguanjia'
  | 'peliten'
  | 'csc'
  | 'wowcarp';
type ApplicationUrlMapType = {
  [K in ApplicationType]: EnvURLMap;
};

let domin = getMainDomain();
domin = domin === 'localhost' ? `icarp.vip` : domin;

export const ApplicationUrlMap: ApplicationUrlMapType = {
  // 帮助中心
  help: {
    dev: 'http://dev-help.icarp.vip',
    qa: 'https://qa-help.icarp.vip',
    prod: 'https://help.icarp.vip',
  },
  // 通用登录
  login: {
    dev: 'http://dev.icarp.vip/account/login',
    qa: 'https://qa.icarp.vip/account/login',
    prod: 'https://console.icarp.vip/account/login',
  },
  // 分销商登录
  dist: {
    dev: 'dev-dist.icarp.vip/account/login',
    qa: 'qa-dist.icarp.vip/account/login',
    prod: 'dist.icarp.vip/account/login',
  },
  // 卡券
  cardStore: {
    dev: 'http://qa-cardstore.fxqifu.com',
    qa: 'https://qa-cardstore.fxqifu.com',
    prod: 'https://cardstore.fxqifu.com',
  },
  // 礼管家
  liguanjia: {
    dev: `https://qa-liguanjia.${domin}`,
    qa: `https://qa-liguanjia.${domin}`,
    prod: `https://liguanjia.${domin}`,
  },
  // 百礼腾
  peliten: {
    dev: 'http://dev-www.peliten.com',
    qa: 'https://qa-www.peliten.com',
    prod: 'https://www.peliten.com',
  },
  // 客服
  csc: {
    dev: `${window.location.origin}/service`,
    qa: `${window.location.origin}/service`,
    prod: `https://csc.${domin}/service`,
  },
  wowcarp: {
    dev: 'https://dev.wowcarp.com',
    qa: 'https://qa.wowcarp.com',
    prod: 'https://console.wowcarp.com',
  },
};

export const getApplicationUrl = (application: ApplicationType, router = '') => {
  return `${ApplicationUrlMap[application][fleAppEnv]}${router}`;
};
export const getApplition = (application: ApplicationType) => {
  return `${ApplicationUrlMap[application][fleAppEnv]}`;
};
