import React from 'react';
import classNames from 'classnames';

import { clearMenuItem } from '@/tools/common';
import { getAuthPath } from '@/tools/getMatchMenu';
import TabLink from '@/components/TabLink';

import type { MenuDataItem } from '@/const/route.config';

import './index.less';

interface HeaderMenuProps {
  menuData: MenuDataItem[];
  flatMenus: Record<string, MenuDataItem> | null;
  matchMenuKeys?: string[];
}
const HeaderMenu: React.FC<HeaderMenuProps> = ({ menuData, flatMenus, matchMenuKeys = [] }) => {
  const clearMenuData = clearMenuItem(menuData || []);

  return (
    <div className="fle-layout-header-menu">
      {clearMenuData.map((item) => {
        return item.name ? (
          <TabLink
            key={item.path}
            className={classNames('fle-layout-header-menu-item', {
              active: matchMenuKeys.includes(item.path || ''),
            })}
            to={getAuthPath(item, flatMenus) || ''}
            target={item.target || '_self'}
          >
            <span className="fle-layout-header-menu-name">{item.name}</span>
            {item.description && (
              <span className="fle-layout-header-menu-description">{item.description}</span>
            )}
          </TabLink>
        ) : null;
      })}
    </div>
  );
};
export default React.memo(HeaderMenu);
