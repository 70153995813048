import { computed, makeAutoObservable, runInAction, toJS } from 'mobx';
import WujieReact from 'wujie-react';
import { ApplicationInfo } from '@/const/application';

const { destroyApp } = WujieReact;
const { dashboard } = ApplicationInfo;

export interface IPageInfo {
  /** 唯一值，默认为path */
  key: string;
  /** 路由 */
  path: string;
  /** 名称 */
  name: string;
  /** 只允许打开单个标签 */
  singleTab?: boolean;
}

export const MAX_TAB_PAGE_COUNT = 30;

class PageCacheStore {
  homePage: IPageInfo = {
    key: '/module/workbench/dashboard', // key 表示无界使用的唯一值，即name
    path: '/module/workbench/dashboard', // 表示子应用的路由
    name: '工作台',
    singleTab: true,
  };

  tabsPages: IPageInfo[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  updatePageTab(info: IPageInfo) {
    if (info.path.indexOf(dashboard) > -1 || info.path === '/redirectPage') return;

    const { key } = info;
    const currentTabIndex = this.getCurrentTabIndexByKey(key);

    // 更新多标签页
    if (currentTabIndex > -1) {
      const newTabsPages = this.tabsPages.map((item) => {
        if (item.key === key) {
          return {
            ...info,
          };
        }
        return item;
      });

      runInAction(() => {
        this.tabsPages = newTabsPages;
      });
    } else {
      // 新增标签页
      runInAction(() => {
        this.tabsPages = [...this.tabsPages, info];
      });
    }
  }

  /**
   * 刷新标签页
   */
  refreshPage = (path: string) => {
    return new Promise((resolve) => {
      try {
        // singleTab 需要刷新
        const { pathname } = new URL(`${window.location.origin}${path}`);
        const tab = this.getCurrentTab(pathname);
        if (tab?.singleTab) {
          destroyApp(tab.key);
          resolve(path);
          return;
        }
      } catch (error) {
        //
      }

      const checkItem = this.getCurrentTab(path);

      if (checkItem) {
        destroyApp(checkItem.key);

        resolve(checkItem.path);
      }

      resolve(path); // TODO: 这里不应该这么写，匹配不到应该抛出错误，但是子应用很多地方跳转都用了这个方法，这是错误的，待后续修改
    });
  };

  /**
   * 刷新标签页
   */
  refreshPageByKey = (key: string) => {
    return new Promise((resolve) => {
      const checkItem = this.getCurrentTabByKey(key);

      if (checkItem) {
        destroyApp(checkItem.key);

        resolve(checkItem.path);
      }
      resolve(key); // TODO: 这里不应该这么写，匹配不到应该抛出错误，但是子应用很多地方跳转都用了这个方法，这是错误的，待后续修改
    });
  };

  /**
   * 关闭标签页
   */
  closePageTab = (path: string, signleTab = false): Promise<string> => {
    return new Promise((resolve, reject) => {
      const refreshIndex = this.getCurrentTabIndex(path);
      if (typeof refreshIndex === 'number') {
        const newTabsPages = this.tabsPages.filter((item) => item.path !== path);

        const { pathname, search } = window.location;
        let redirectPath = '';

        if (path === `${pathname}${signleTab ? '' : search}`) {
          redirectPath = newTabsPages[newTabsPages.length - 1]?.path || this.homePage?.path;
        }

        runInAction(() => {
          destroyApp(path);
          this.tabsPages = newTabsPages;
        });

        resolve(redirectPath);
      }
      reject();
    });
  };

  /**
   * 关闭所有标签页
   */
  closeAllTabs() {
    runInAction(() => {
      const copyTabsPages = [...this.tabsPages];

      copyTabsPages.forEach((item) => {
        destroyApp(item.key);
      });
      this.tabsPages = [];
    });
  }

  getCurrentTabIndex(path?: string) {
    const { pathname, search } = window.location;
    return this.tabsPages.findIndex((item) => item.path === (path || `${pathname}${search}`));
  }

  getCurrentTabIndexByKey(key?: string) {
    return this.tabsPages.findIndex((item) => item.key === key);
  }

  getCurrentTab(path?: string) {
    const { pathname, search } = window.location;
    return this.tabsPages.find((item) => item.path === (path || `${pathname}${search}`));
  }

  getCurrentTabByKey(key?: string) {
    return this.tabsPages.find((item) => item.key === key);
  }

  @computed
  get alivePages() {
    return this.tabsPages;
  }

  @computed
  get currentTabIndex() {
    const { pathname, search } = window.location;
    return this.tabsPages.findIndex((item) => item.path === `${pathname}${search}`);
  }
}

export default new PageCacheStore();
