import React, { useState, useEffect, useCallback, useRef } from 'react';
import { observer } from 'mobx-react';
import { Popover, Avatar, Space, Button, Spin, Tooltip } from 'antd';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import { Icon, CreateTeamModal } from '@/components';
import { AccountStore } from '@/stores';
import { useTabNavigate } from '@/hooks/useTabNavigate';
import { getApplicationUrl, getApplition } from '@/const/serviceEnv';
import { pptxUploadQueue } from '@/pages/microApp';

import type { UserInfo, Company } from '@/stores/account';
import type { CreateTeamRefType } from '@/components/CreateTeamModal';

import './index.less';

interface HeaderLeftContentProps {
  children: React.ReactNode;
  userInfo?: UserInfo;
}

const CommonLoginLink = getApplicationUrl('login').replace(/^https?:\/\//i, '');

const tagMap = new Map([
  [
    1,
    {
      className: 'login-main-tag',
      text: '已授权',
    },
  ],
  [
    2,
    {
      className: 'login-gray-tag',
      text: '未授权',
    },
  ],
  [
    3,
    {
      className: 'login-red-tag',
      text: '已过期',
    },
  ],
]);

const HeaderLeftContent: React.FC<HeaderLeftContentProps> = ({ children, userInfo }) => {
  const navigate = useTabNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [companyList, setCompanyList] = useState<Company[]>([]);

  const CreateTeamRef = useRef<CreateTeamRefType | null>(null);

  const CookiesLink = Cookies.get('loginLink')?.replace(/^https?:\/\//i, '');
  const CookiesLoginLink = CookiesLink || `${location.hostname}/account/login`;
  const loginLink = userInfo?.loginLink
    ? `https://${userInfo?.loginLink}`
    : `https://${CookiesLoginLink}`;
  const isCommonLoginLink =
    CookiesLoginLink.indexOf(CommonLoginLink) > -1 ||
    CookiesLoginLink.indexOf(getApplition('dist')) > -1;
  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      const data = await AccountStore.getCompanyList({
        loginLink: isCommonLoginLink ? CommonLoginLink : userInfo?.loginLink || CookiesLoginLink,
      });
      setLoading(false);
      const filterCompany = data.filter((item) => item.id !== userInfo?.id);
      const currentCompany: Company[] = userInfo
        ? [
            {
              id: userInfo?.id,
              companyName: userInfo?.companyName,
              certifyStatus: userInfo?.certificationStatus,
              companyId: userInfo?.companyId,
              name: userInfo?.name,
              joinStatus: 1,
              authorizeStatus: 1, // 默认是未授权状态
            },
          ]
        : [];
      setCompanyList([...currentCompany, ...filterCompany]);
    };

    open && fetch();
  }, [open]);

  const changeCompany = useCallback(
    (id: string) =>
      AccountStore.changeCompany({ id }).then((token) => {
        Cookies.set('token', token);
        window.location.href = window.location.origin;
      }),
    [],
  );

  const content = useCallback(() => {
    return (
      <div className="avatar-popover-content">
        <div
          className="avatar-popover-status"
          onClick={() => {
            navigate('/module/workbench/account/accountInfo');
          }}
        >
          <img
            src="//oss.elebuys.com/resource/goods/materials/202404081521420000799390.png"
            alt=""
          />
        </div>
        <Space className="avatar-popover-company">
          <Avatar
            size={50}
            icon={<Icon type="icon-qiye-moren" style={{ fontSize: 30 }} />}
            style={{ background: '#fff', color: '#2C6BFF' }}
          />
          <div className="flex flex-vertical">
            <Tooltip title={userInfo?.companyName}>
              <div
                className="avatar-popover-title"
                onMouseEnter={(e) => {
                  const target = e.target as HTMLElement;
                  if (target.clientWidth >= target.scrollWidth) {
                    target.style.pointerEvents = 'none'; // 阻止鼠标事件
                  }
                }}
              >
                {userInfo?.companyName}
              </div>
            </Tooltip>
            <div className="flex">
              {userInfo?.certificationStatus === 0 ? (
                <span
                  onClick={() => {
                    navigate('/module/workbench/account/accountInfo');
                  }}
                  className="avatar-popover-certification-tag grey"
                >
                  <Icon type="icon-renzheng1" /> 未认证
                </span>
              ) : (
                <span className="avatar-popover-certification-tag blue">
                  <Icon type="icon-renzheng1" />
                  已认证
                </span>
              )}
              {userInfo?.adminFlag ? (
                <span
                  onClick={() => {
                    navigate('/module/workbench/vipLevel');
                  }}
                  className="avatar-popover-certification-tag greylight"
                >
                  <Icon type="icon-wodequanyi" />
                  企业标准版
                </span>
              ) : null}
            </div>
          </div>
        </Space>
        <Space className="avatar-popover-user">
          <Avatar
            size={40}
            src="//oss.elebuys.com/tmpdir/202308171743390002128752.png"
            style={{ background: '#bbcfff' }}
          />
          <div className="flex flex-vertical">
            <Space>
              <span>{userInfo?.name}</span>
              <span>{userInfo?.mobile.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')}</span>
            </Space>
            <div style={{ color: '#aaa' }}>{`ID.${userInfo?.id}`}</div>
          </div>
        </Space>

        {!!companyList.length && (
          <div className="avatar-popover-company-card">
            {/* <div className="avatar-popover-company-card-title">企业切换</div> */}
            <Spin spinning={loading}>
              <div className={classNames('avatar-popover-company-list', 'fle-scroll')}>
                {companyList.map((item) => {
                  return (
                    <div
                      key={item.id}
                      className={classNames('avatar-popover-company-item', {
                        active: item.id === userInfo?.id,
                      })}
                      onClick={() => item.id !== userInfo?.id && changeCompany(item.id)}
                    >
                      <Avatar
                        size={40}
                        icon={<Icon type="icon-qiye-moren" style={{ fontSize: 24 }} />}
                        style={{ background: '#fff', color: '#2C6BFF' }}
                      />
                      <span className="avatar-popover-company-name">{item.companyName}</span>
                      {
                        // 当前选中的企业不显示标签
                        item.id !== userInfo?.id && (
                          <span
                            className={`${tagMap.get(item.authorizeStatus)?.className} login-tag`}
                          >
                            {tagMap.get(item.authorizeStatus)?.text}
                          </span>
                        )
                      }
                      {/* {item.id === userInfo?.id ? (
                        <span className="avatar-popover-company-type">当前</span>
                      ) : (
                        <Icon
                          type="icon-qiehuan"
                          className="avatar-popover-company-change"
                          style={{ color: '#2C6BFF', fontSize: 18 }}
                          onClick={() => changeCompany(item.id)}
                        />
                      )} */}
                    </div>
                  );
                })}
              </div>
            </Spin>
          </div>
        )}

        {isCommonLoginLink && (
          <Button
            ghost
            block
            type="primary"
            className="avatar-popover-blcok-btn"
            onClick={() => {
              setOpen(false);
              CreateTeamRef.current?.setModalVisible(true);
            }}
          >
            创建新团队
          </Button>
        )}
        <Button
          ghost
          block
          type="primary"
          className="avatar-popover-blcok-btn"
          onClick={() => {
            Promise.allSettled([AccountStore.logout?.(), AccountStore.logoutother?.()]).then(
              (res) => {
                if (res.filter((item) => item.status === 'fulfilled').length > 0) {
                  [
                    'token',
                    'liguanjia_member_token',
                    'liguanjia_supplier_token',
                    'liguanjia_card_token',
                    'liguanjia_product_token',
                  ].forEach(async (item: string) => {
                    await Cookies.remove(item);
                  });

                  pptxUploadQueue.cleanAuth();
                  window.location.replace(loginLink);
                }
              },
            );
          }}
        >
          退出登录
        </Button>
      </div>
    );
  }, [userInfo, companyList, loading]);

  return (
    <>
      <Popover
        open={open}
        trigger="hover"
        content={content}
        arrow={false}
        rootClassName="avatar-popover"
        overlayInnerStyle={{ padding: 0, marginRight: 10 }}
        onOpenChange={setOpen}
      >
        {children}
      </Popover>
      <CreateTeamModal ref={CreateTeamRef} />
    </>
  );
};
export default React.memo(observer(HeaderLeftContent));
