import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import type { IPageInfo } from '@/stores/pageCacheStore';
import { Icon } from '@/components';

import './index.less';

interface PageTabProps {
  item: IPageInfo;
  iconRender?: () => React.ReactNode;
  onDelete?: () => void;
}

function PageTab({ item, iconRender, onDelete }: PageTabProps) {
  const { pathname, search } = window.location;

  const active = item.path === `${pathname}${item.singleTab ? '' : search}`;
  
  return (
    <div
      className={classNames('page-tab', {
        active,
      })}
      key={item.key}
    >
      <Link className="page-tab-link" to={item.path}>
        {iconRender ? iconRender() : null}
        {item.name}
      </Link>
      {onDelete ? (
        <Icon className="page-tab-close" type="icon-biaoqianyeguanbi" onClick={onDelete} />
      ) : null}
    </div>
  );
}

export default React.memo(PageTab);
